export default [
  {
    header: 'Tanimlamalar',
  },
  {
    title: 'Tanimlamalar',
    icon: 'ListIcon',
    children: [
      {
        title: 'Kategoriler',
        route: 'kategoriler',
      },
      {
        title: 'Ürün Kategoriler',
        route: 'urun-kategoriler',
      },
      // {
      //   title: 'Bölgeler',
      //   route: 'bolgeler',
      // },
      // {
      //   title: 'İptal Politikaları',
      //   route: 'iptal-politikalari',
      // },
      // {
      //   title: 'Taglar',
      //   route: 'taglar',
      // },
      // {
      //   title: 'Ekstra Hizmetler',
      //   route: 'ekstra-hizmetler',
      // },
      {
        title: 'Kur İşlemleri',
        route: 'kur-islemleri',
      },
    ],
  },
];
