export default [
  {
    header: 'ÜRÜN İŞLEMLERİ',
  },
  {
    title: 'Ürünler',
    icon: 'ShoppingCartIcon',
    route: 'urun',
  },
  // {
  //   title: 'Rezervasyonlar',
  //   icon: 'LayersIcon',
  //   route: 'rezervasyonlar',
  // },
  // {
  //   title: 'Finans',
  //   icon: 'CreditCardIcon',
  //   route: 'finans',
  // },
  {
    title: 'Yorum & Puan',
    icon: 'TwitchIcon',
    route: 'yorumlar',
  },

  // {
  //   title: 'Üyeler',
  //   icon: 'UsersIcon',
  //   route: 'uyeler',
  // },
];
